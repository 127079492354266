const supportedLocales = {
  tr: "Türkçe",
  en: "English",
  de: "Deutsch",
};

export function getSupportedLocales() {
  let annotatedLocales = [];

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code],
    });
  }

  return annotatedLocales;
}

export function getCountries() {
  return [
    {
      flag: "en.png",
      name: "English",
      code: "en",
    },
    {
      flag: "tr.png",
      name: "Türkçe",
      code: "tr",
    },
    {
      flag: "de.png",
      name: "Deutsch",
      code: "de",
    },
  ];
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale);
}
